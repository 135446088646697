import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/vincent/Documents/Develop/Web/PersonalWebsite/website/src/components/layout-markdown.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Over the last 7 years, I've been asking myself how to develop new ideas faster while having more fun, so I've making lots of prototypes to change my development practices.`}</p>
    <p>{`Slowly, pieces of the puzzle feel into place and I started to experiment with architecting software so I could jump to any state of the UI immediately. For example, I could simulate users interacting in different ways resulting in different combinations of things in their activity feeds, so I could reproduce see all edge cases and style them. Then, I realized I could run these simulations in parallel and give me a god-like overview of many different cases side-by-side, so I immediately see the impact of style changes across the product.`}</p>
    <p>{`This lined up with what I was learning from other, more experienced programmers: awesome developer tooling doesn't only lead to faster iteration speed and better products, it also brings back a lot of joy to crafting your product.`}</p>
    <p>{`Then, I discovered Handmade Hero, a series of videos describing how to make a video game engine from scratch. I learned that even in C, memory management is a lot simpler than what we've been told and you can hot-reload an entire game with minimal effort if you just set things up the right way. Also, I learned just how fast modern computers are and that with minimal care using a low-level language, you can make products that are easily 100x faster than lots of other products.`}</p>
    <p>{`So my goal over the next year is to get better at systems programming using Data Oriented Design while prototyping different ideas and write about them. First up is a prototype I've `}<a parentName="p" {...{
        "href": "/blog/architecture-docs-prototype/"
      }}>{`written in Zig to visualize system architectures`}</a>{` and learn about (YAML and Typescript) parsing, graph visualization and reasoning from first principles about software performance.`}</p>
    <p>{`If any of this sounds interesting and you'd like to exchange ideas, feel free to reach out!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      